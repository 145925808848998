import Vue from 'vue'
import { mapActions } from 'vuex'
import filterMixin from '../components/mixin'

export default Vue.extend<any, any, any, any>({
  mixins: [filterMixin],
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onClickOpenFilters () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "jobs-modals" */ '../FilterModal/FilterModal.vue'),
          title: 'Filters',
          props: {}
        }
      })
    },
  }
})
