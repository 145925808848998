import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import pickBy from 'lodash/pickBy'
import { stripDescriptionTags } from '@/utils-ts/strings'
import { META_COUNTRIES } from '@/constants/meta'
import { addMonths, formatDate } from '@/utils/date'
import { DATE_TIME_FORMAT_WITHOUT_SEC } from '@/constants/utils/date'
import {
  VACANCIES,
  BROWSE_VACANCIES_BY_SKILL,
  VACANCY_DETAILS,
  BROWSE_VACANCIES_BY_SKILL_COUNTRY,
  BROWSE_VACANCIES_BY_SKILL_COUNTRY_CITY
} from '@/constants/routes'
import ErrorMatcher from '@/utils/ErrorMatcher'
import VacancyListCard from '@/partials/VacancyCards/VacancyListCard/VacancyListCard.vue'
import SeoQuestions from '@/partials/SeoQuestions/SeoQuestions.vue'
import Faq from '@/partials/Faq/Faq.vue'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import seoSkillMixin from '@/mixins/seoSkillMixin'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'
import questions from './questions'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import Skill from '@/models-ts/Skill'
import { ISkillsState } from '@/store/shared/modules/skills/types'
import { IBrowseVacanciesState } from '@/store/shared/modules/browse-vacancies/types'
import FilterCard from './FilterCard/FilterCard.vue'
import BlockchainInfoSection from './BlockchainInfoSection/BlockchainInfoSection.vue'
import { IVacancyRolesState } from '@/store/shared/modules/vacancyRoles/types'
import VacancyListItem from '@/models-ts/vacancies/VacancyListItem'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'
import { getListPages, ListPagesType } from '@/api/sitemap'
import VacancyLocation from '@/models-ts/vacancies/VacancyLocation'
import { cloneDeep } from 'lodash'
import { MAX_BUDGET, MIN_BUDGET, SORT_FEATURED, SORT_NEW, VACANCIES_LIMIT, AVG_SKILLS } from './components/constants'
import VacancyRole from '@/models-ts/vacancies/VacancyRole'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, rolebleMixin, canonicalMixin, seoSkillMixin],
  components: {
    BlockchainInfoSection,
    Faq,
    FilterCard,
    InfiniteLoading,
    NoSsr,
    SeoQuestions,
    VacancyListCard,
  },
  data () {
    return {
      VACANCIES,
      questions,
      VACANCY_DETAILS,
      totalLoading: false,
      filtersCount: 0,
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState): boolean => state.authorized,
    }),
    ...mapState<IBrowseVacanciesState>('browseVacancies', {
      isLoading: (state: IBrowseVacanciesState) => state.vacancies.isLoading,
      isLoaded: (state: IBrowseVacanciesState) => state.vacancies.isLoaded,
      pagination: (state: IBrowseVacanciesState) => state.vacancies.pagination,
      prefetched: (state: IBrowseVacanciesState) => state.prefetched,
      cityName: (state: IBrowseVacanciesState) => state.cityName,
      countryName: (state: IBrowseVacanciesState) => state.countryName,
    }),
    ...mapState('user', {
      userId: (state: any) => state.id,
    }),
    ...mapState<ISkillsState>('skills', {
      predefinedSkills: (state: ISkillsState) => state.skills.value,
      deprecatedSkills: (state: ISkillsState) => state.deprecatedSkills,
      skillsLoading: (state: ISkillsState) => state.skills.isLoading,
    }),
    ...mapGetters({
      vacancies: 'browseVacancies/vacancies',
      getRoleByURL: 'vacancyRoles/getRoleByURL',
      roleOptions: 'vacancyRoles/roleOptions',
    }),
    hasSeoBlock () {
      return this.$route.name === VACANCIES
    },
    isBrowseBySkill () {
      return this.$route.name === BROWSE_VACANCIES_BY_SKILL
    },
    isBrowseBySkillAndCountry () {
      return this.$route.name === BROWSE_VACANCIES_BY_SKILL_COUNTRY
    },
    isBrowseBySkillAndCountryAndCity () {
      return this.$route.name === BROWSE_VACANCIES_BY_SKILL_COUNTRY_CITY
    },
    isPlacementPage () {
      return this.isBrowseBySkillAndCountry || this.isBrowseBySkillAndCountryAndCity
    },
    isPlacementListEmpty () {
      return this.isPlacementPage && this.isLoaded && !this.vacancies.length
    },
    placementSlug () {
      const city = this.$route.params.city
      const country = this.$route.params.country
      const skill = this.$route.params.skill
      if (!this.isPlacementPage) return null
      if (this.isBrowseBySkillAndCountry) {
        return `${skill}/${country}`
      }
      return `${skill}/${country}/${city}`
    },
    placementListEmptyMessagePart () {
      if (this.isPlacementListEmpty) {
        return this.isBrowseBySkillAndCountry
          ? `${this.skillDetails?.name} in ${this.countryName}`
          : `${this.skillDetails?.name} in ${this.cityName}, ${this.countryName}`
      }
    },
    skillDetails () {
      const skill = this.$route.params?.skill
      if (skill) {
        return [...this.predefinedSkills, ...this.deprecatedSkills].find((s: any) => s.url === skill)
      }
      return null
    },
    roleDetails () {
      const role = this.$route.params?.role
      if (role) {
        return this.getRoleByURL(role)
      }
    },
    pageTitle () {
      if (this.skillDetails?.name) {
        if (this.isPlacementPage && this.countryName) {
          const postfix = this.isBrowseBySkillAndCountry ? this.countryName : this.cityName
          return `${this.skillDetails?.name || ''} Jobs in Web3 in ${postfix}`
        } else if (this.currentPage > 1) {
          return `${this.skillDetails?.name} - Page ${this.currentPage}`
        }
        return `${this.skillDetails?.name || ''} Jobs in Web3 & Crypto`
      }
      if (this.roleDetails?.name) return `Full-time ${this.roleDetails?.name} Jobs`
      return 'Browse Full-time Jobs'
    },
    total () {
      return this.pagination.total
    },
    totalFoundText () {
      return this.skillDetails?.name &&
                this.isBrowseBySkill &&
                // eslint-disable-next-line max-len
                `Check out of ${this.total} posted ${this.skillDetails.name} Jobs. At LaborX you have a great option to choose a freelance job for ${this.skillDetails.name}. Apply a job now and get paid in cryptocurrency.`
    },
    seoDescription () {
      if (!this.isLoaded) {
        return null
      }
      if (this.skillDetails?.name) {
        return `We found ${this.total} ${this.skillDetails.name} Job${this.total !== 1 ? 's' : ''} that paid in WEB3 and Crypto companies.
Search for work, find ${this.skillDetails.name} job that suits your needs, and apply now!`
      }
    },
    avgSkills () {
      return AVG_SKILLS
        .map((url: string) => {
          return this.predefinedSkills.find((sk: any) => sk.url === url)
        })
        .filter(Boolean)
        .sort((a: any, b: any) => b.level === a.level ? b.id - a.id : b.level - a.level)
        .map((skill: any) => ({
          text: skill.name,
          link: { name: BROWSE_VACANCIES_BY_SKILL, params: { skill: skill.url } }
        }))
    },
    hasAvgReviews () {
      return this.skillDetails?.customers_score_based_on > 0 && this.skillDetails?.customers_score
    },
    ratingScore () {
      return this.hasAvgReviews && this.skillDetails?.customers_score >= 4.6
        ? this.skillDetails?.customers_score
        : 5
    },
    ratingScoreLbl () {
      const rt = Number(this.ratingScore || 0)
      return (rt % 1 === 0)
        ? rt
        : rt.toFixed(2)
    },
    basedOnScore () {
      const based = Number(this.skillDetails.customers_score_based_on)
      return based > 1000 ? based : based + 1000
    },
    totalPages () {
      return Math.ceil(this.pagination.total / VACANCIES_LIMIT)
    },
    currentPage () {
      let page: string = this.$route?.query?.page
      return Number.isNaN(Number(page))
        ? 1
        : Math.max(Number.parseInt(page), 1)
    },
    hasAdditionSection () {
      return this.isBrowseBySkill && this.isLoaded &&
        (this.pagination.total <= VACANCIES_LIMIT || this.currentPage <= 1)
    },
  },
  watch: {
    $route: {
      async handler () {
        this.reloadVacancies()
        lxAnalytics.send('browse-ftj', this.getFilterFromQuery())
      },
    },
    '$route.params.city' () {
      this.resetPlacement()
    },
    '$route.params.country' () {
      this.resetPlacement()
    },
  },
  async prefetch () {
    if (process.server) {
      try {
        const skillUrl = this.$route.params.skill
        let predefinedSkills = []
        try {
          predefinedSkills = await this.getSkills()
        } catch (e) {
          console.error('Error fetching skills:', e)
        }
        const roleURL = this.$route.params.role

        if (skillUrl) {
          if (!predefinedSkills.find((skill: Skill) => skill.url === skillUrl)) {
            this.setNotFound(true)
            this.setPrefetched(true)
            return
          }
          if (this.skillDetails.change_id) {
            const newSkill = predefinedSkills.find((skill: Skill) => skill.id === this.skillDetails.change_id)
            if (newSkill) {
              this.setRedirect(this.$route.fullPath.replace(this.skillDetails.url, newSkill.url))
              return
            }
          }
          if (this.skillDetails.is_removed) {
            this.setRedirect('/vacancies')
            return
          }
        }
        if (this.isPlacementPage) {
          try {
            const result = await getListPages({ type: ListPagesType.VACANCIES_LIST, slug: this.placementSlug })
            if (!result?.length) {
              this.setCityName(this.$route.params.city)
              this.setCountryName(this.$route.params.country)
              this.setNotFound(true)
              this.setPrefetched(true)
              return
            }
            this.setCityName(result[0].body?.city)
            this.setCountryName(result[0].body?.country)
          } catch (e) {
            console.dir(e)
            this.setNotFound(true)
            this.setPrefetched(true)
            return
          }
        }
        await this.getRoles()
        if (roleURL) {
          let roleId = this.getRoleByURL(roleURL)?.id
          if (!roleId) {
            this.setNotFound(true)
            this.setPrefetched(true)
            return
          }
        }
        const hasPageInQuery = 'page' in this.$route.query
        let pageFromQuery = Number(this.$route.query?.page || 1)
        pageFromQuery = pageFromQuery > 1 ? pageFromQuery : 1
        this.setPagination({ limit: VACANCIES_LIMIT, offset: 0 })
        await this.loadVacancies(this.getFilterFromQuery())
        if (!hasPageInQuery) {
          this.setPrefetched(true)
        } else if (pageFromQuery > this.totalPages) {
          const curPage = this.totalPages > 1 ? this.totalPages : 0
          const url = new URL(this.$route.fullPath, process.env.VUE_APP_FRONTEND_URL)
          if (curPage) {
            url.searchParams.set('page', curPage)
          } else {
            url.searchParams.delete('page')
          }
          let newUrl = url.searchParams.toString()
          newUrl = newUrl ? `${this.$route.path}?${newUrl}` : this.$route.path
          this.setRedirect(newUrl)
        } else if (pageFromQuery === 1) {
          const url = new URL(this.$route.fullPath, process.env.VUE_APP_FRONTEND_URL)
          url.searchParams.delete('page')
          let newUrl = url.searchParams.toString()
          newUrl = newUrl ? `${this.$route.path}?${newUrl}` : this.$route.path
          this.setRedirect(newUrl)
        } else {
          this.setPrefetched(true)
        }
      } catch (e) {
        if (ErrorMatcher.isNotFound(e)) {
          this.setNotFound(true)
        } else {
          console.error('Prefetch vacancy error:', e)
        }
      }
      this.$store.commit('responseHeaders/disableCacheControl')
    }
  },
  async created () {
    if (process.client) {
      try {
        if (!this.prefetched) {
          this.setPagination({ limit: VACANCIES_LIMIT, offset: 0 })
          const initPlacement = async () => {
            if (this.isPlacementPage) {
              const result = await getListPages({ type: ListPagesType.VACANCIES_LIST, slug: this.placementSlug })
              if (!result?.length) {
                this.setCityName(this.$route.params.city)
                this.setCountryName(this.$route.params.country)
                this.setNotFound(true)
                return
              }
              this.setCityName(result[0].body?.city)
              this.setCountryName(result[0].body?.country)
            }
          }
          await Promise.all([this.getSkills(), this.getRoles(), initPlacement()])
          await this.loadVacancies(this.getFilterFromQuery())
        }
        this.setPrefetched(false)
      } catch (e) {
        this.parseError(e)
      }
    }
  },
  methods: {
    ...mapActions({
      loadVacancies: 'browseVacancies/loadVacancies',
      loadMoreVacancies: 'browseVacancies/loadMoreVacancies',
      getSkills: 'skills/getSkills',
      getRoles: 'vacancyRoles/getRoles',
      openModal: 'ui/openModal',
    }),
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'browseVacancies/setPrefetched',
      setPagination: 'browseVacancies/setPagination',
      addBookmark: 'browseVacancies/addBookmark',
      removeBookmark: 'browseVacancies/removeBookmark',
      setCityName: 'browseVacancies/setCityName',
      setCountryName: 'browseVacancies/setCountryName',
      resetPlacement: 'browseVacancies/resetPlacement',
    }),
    async reloadVacancies () {
      this.setPagination({ limit: VACANCIES_LIMIT, offset: 0 })
      await this.loadVacancies(this.getFilterFromQuery())
    },
    async onChangePage (page: number) {
      const filters = cloneDeep(pickBy({
        ...this.$route.query,
        page: page > 1 ? page : '',
      }, Boolean))
      let name = this.$route.name
      let params = {
        ...this.$route.params,
        savePosition: true,
      }
      this.$router.replace({
        name,
        params,
        query: filters,
      }).catch(() => {})
    },
    getFilterFromQuery () {
      let {
        title,
        orderField,
        role = [],
        orderType,
        page,
        our,
        skill = [],
        budgetFrom,
        budgetTo
      } = this.$route.query
      this.filtersCount = 0
      if (!page || Number.isNaN(Number(page)) || Number(page) < 1) {
        page = 1
      } else {
        page = Math.max(Number.parseInt(page), 1)
        this.filtersCount += 1
      }
      this.setPagination({ limit: VACANCIES_LIMIT, offset: (page - 1) * VACANCIES_LIMIT })
      title = title?.trim()
      if (title) this.filtersCount += 1
      const bFrom = (Number.isNaN(+budgetFrom) || +budgetFrom === MIN_BUDGET) ? null : +budgetFrom
      const bTo = (Number.isNaN(+budgetTo) || +budgetTo === MAX_BUDGET) ? null : +budgetTo

      let skillsValue = [] as Array<Skill['id']>
      if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (mainSkill) {
          skillsValue.push(mainSkill.id)
        }
      }
      let skillsArray = Array.isArray(skill) ? skill : [skill]
      skillsValue = skillsValue.concat(skillsArray
        .map(id => this.predefinedSkills.find((opt: any) => +opt.id === +id)?.id)
        .filter(Boolean)
      )
      skillsValue = Array.from(new Set(skillsValue))

      let primaryRolesIds = [] as Array<VacancyRole['id']>
      if (this.$route.params.role) {
        const roleId = this.getRoleByURL(this.$route.params.role)
        if (roleId) {
          primaryRolesIds.push(roleId.id)
        }
      }
      let rolesArray = Array.isArray(role) ? role : [role]
      primaryRolesIds = primaryRolesIds.concat(rolesArray
        .map(id => this.roleOptions.find((opt: any) => +opt.id === +id)?.id)
        .filter(Boolean)
      )
      primaryRolesIds = Array.from(new Set(primaryRolesIds))

      const cityName = this.$route.params.city
      const countryName = this.$route.params.country

      const hasntSortInQuery = !orderField || !orderType
      const defaultSort = orderField === SORT_NEW.field && orderType === SORT_NEW.dir
      this.filtersCount = this.filtersCount +
        (bFrom ? 1 : 0) +
        (bTo ? 1 : 0) +
        (skillsValue.length > 0 ? 1 : 0) +
        (primaryRolesIds.length > 0 ? 1 : 0)
      if ((hasntSortInQuery || defaultSort) && this.filtersCount === 0) {
        orderField = SORT_FEATURED.field
        orderType = SORT_FEATURED.dir
      } else if (orderField === SORT_FEATURED.field && (this.filtersCount > 0 || orderType !== SORT_NEW.dir)) {
        orderField = SORT_NEW.field
        orderType = SORT_NEW.dir
      }

      return pickBy({
        title,
        orderField,
        orderType,
        skills: skillsValue,
        primaryRolesIds,
        cityName,
        countryName,
        rateFrom: bFrom,
        rateTo: bTo,
        ...(our && { isEmptyExternal: true }),
      }, Boolean)
    },
  },
  metaInfo () {
    const script = []
    const defaultLocations = META_COUNTRIES.map(name => ({
      '@type': 'Country', name
    }))
    const metaTotal = '460'
    const roleName = this.roleDetails?.name || this.skillDetails?.name
    let metaTitle = ''
    let description = ''
    if (this.isPlacementPage) {
      const postfix = this.isBrowseBySkillAndCountryAndCity ? `${this.cityName}, ${this.countryName}` : this.countryName
      metaTitle = `${roleName} jobs in Web3 & Crypto in ${postfix}`
      // eslint-disable-next-line max-len
      description = `★ Find the best ${roleName} Jobs with LaborX. Large selection of Web3 & Crypto jobs in ${this.isBrowseBySkillAndCountryAndCity ? this.cityName : this.countryName} with daily updates ✔.`
    } else if (this.skillDetails?.name) {
      metaTitle = this.pageTitle
      if (this.currentPage > 1) {
        description = `${this.pageTitle}. Large selection of Web3 & Crypto jobs in our catalog with daily updates ✔.`
      } else {
        // eslint-disable-next-line max-len
        description = `★ Find the best ${this.skillDetails?.name} Jobs with LaborX. Large selection of Web3 & Crypto jobs in our catalog with daily updates ✔.`
      }
    } else {
      const vacancyCount = this.skillDetails?.meta?.previous_month_count?.vacancies
      const postfix = vacancyCount ? ` (${vacancyCount} New)` : ''
      metaTitle = roleName
        ? `${roleName} Web3 Jobs - ${formatDate(new Date(), 'MMM YYYY')}${postfix}`
        : `${metaTotal}+ Full-time Web3 Jobs & Crypto Jobs (${formatDate(new Date(), 'MMMM YYYY')})`
      description = roleName
        // eslint-disable-next-line max-len
        ? `Looking for ${roleName} full time Jobs? ➔ Choose out of best vacancies in ${formatDate(new Date(), 'MMMM YYYY')} ➔ Find and apply to ${roleName} Jobs now on LaborX`
        : 'Browse full-time Blockchain and Crypto Jobs now. Find a Cryptocurrency job at LaborX and get paid in crypto.'
    }

    const mapLocation = (l: VacancyLocation) => {
      const data = {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressCountry: l.country,
        }
      }
      if (l.country !== l.city) {
        // @ts-ignore
        data.address.addressRegion = l.city
      }
      return data
    }
    for (let vacancy of (this.vacancies as VacancyListItem[])) {
      let category = ''
      let skills = ''
      if (this.predefinedSkills?.length) {
        for (const skill of vacancy.skills) {
          const isChild = this.predefinedSkills.find((s: Skill) => s.id === skill.id)?.relations?.Parent?.length
          if (isChild) {
            skills = skills ? `${skills}, ${skill.name}` : skill.name
          } else {
            category = skill.name
          }
        }
      }
      script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'JobPosting',
          title: vacancy.name,
          description: stripDescriptionTags(vacancy.description, { stripLinks: true }),
          ...((vacancy.position_remote && !vacancy.position_office) && {
            jobLocationType: 'TELECOMMUTE',
          }),
          ...(vacancy.position_office && vacancy.locations?.length && {
            jobLocation: vacancy.locations.map(mapLocation)
          }),
          applicantLocationRequirements: defaultLocations,
          hiringOrganization: {
            '@type': 'Organization',
            name: vacancy.user?.name,
            // @ts-ignore
            ...(vacancy.user?.avatar?.src && { logo: vacancy.user.avatar?.src }),
          },
          ...(vacancy.salary_from && {
            baseSalary: {
              '@type': 'MonetaryAmount',
              currency: 'USD',
              value: {
                '@type': 'QuantitativeValue',
                unitText: 'YEAR',
                ...(vacancy.salary_type === SalaryTypes.FIXED && { value: vacancy.salary_from }),
                ...(vacancy.salary_type === SalaryTypes.RANGE && { minValue: vacancy.salary_from, maxValue: vacancy.salary_to }),
              },
            },
          }),
          ...(category && { occupationalCategory: category }),
          ...(skills && { skills }),
          datePosted: formatDate(vacancy.published_at, 'YYYY-MM-DD'),
          validThrough: formatDate(addMonths(vacancy.published_at, 3), DATE_TIME_FORMAT_WITHOUT_SEC),
        }
      })
    }
    return {
      title: metaTitle,
      meta: [
        {
          name: 'description',
          content: description,
        },
      ],
      script,
      link: [this.canonicalLink]
    }
  },
})
